import {FocusLock} from "./_focus-lock";

const titles = document.querySelectorAll('.post :where(h2, h3, h4, h5, h6)');

function createDataTitle(title) {

  // 1. Транслитерируем кириллицу
  const transliteratedTitle = title.textContent.trim().toLowerCase().replace(/а/g, 'a').replace(/б/g, 'b').replace(/в/g, 'v').replace(/г/g, 'g').replace(/д/g, 'd').replace(/е/g, 'e').replace(/ё/g, 'e').replace(/ж/g, 'zh').replace(/з/g, 'z').replace(/и/g, 'i').replace(/й/g, 'y').replace(/к/g, 'k').replace(/л/g, 'l').replace(/м/g, 'm').replace(/н/g, 'n').replace(/о/g, 'o').replace(/п/g, 'p').replace(/р/g, 'r').replace(/с/g, 's').replace(/т/g, 't').replace(/у/g, 'u').replace(/ф/g, 'f').replace(/х/g, 'h').replace(/ц/g, 'ts').replace(/ч/g, 'ch').replace(/ш/g, 'sh').replace(/щ/g, 'shch').replace(/ъ/g, '').replace(/ы/g, 'y').replace(/ь/g, '').replace(/э/g, 'e').replace(/ю/g, 'yu').replace(/я/g, 'ya');

  // 2. Заменяем пробелы и подчеркивания на "-". Если в итоге получилось "---", меняем на одно "-"
  const formattedTitle = transliteratedTitle.replace(/[\s_]/g, '-').replace(/---/g, '-');

  // 3. Удаляем символы запрещенные в URL
  const safeTitle = formattedTitle.replace(/[^a-zA-Z0-9\-]/g, '');

  // 4. Сокращаем длину до 25 символов
  const shortTitle = safeTitle.substring(0, 45);

  if (title.id === '') {
    title.id = shortTitle;
  }

  //title.innerHTML  = `<a href="#${shortTitle}">${title.innerHTML}</a>`
  title.innerHTML  = `<a href="#${title.id}">${title.innerHTML}</a>`
}


titles.forEach(title => {
  createDataTitle(title);
});


