//////////////////////////////////////////////////////////////////
// _theme-toggle
document.querySelectorAll('.theme-toggle__radio').forEach(radio => {
  // Покажем какое радио чекнутое исходя из того, что в localStorage
  if (radio.value === theme) {
    radio.checked = true;
  }


  radio.addEventListener('change', () => {
    theme = radio.value;
    document.documentElement.setAttribute('data-color-theme', theme);
    localStorage.setItem('color-theme', theme);

    ym(5274517, 'params', {user_data: {color_theme: theme}});
  })

});

/*
    // меняем и тему песочниц codepen
    // todo: нужно сделать смену при загрузке, потом включить эту переключалку
    document.querySelectorAll('.cp_embed_iframe').forEach((pen) => {
      let url = new URL(pen.src);
      url.searchParams.set('theme-id', theme); // меняем параметр темы в url
      pen.src = url;
    });
*/


// end _theme-toggle
//////////////////////////////////////////////////////////////////
