"use strict"

import '../../node_modules/prismjs/prism.js';

import '../../node_modules/prismjs/plugins/unescaped-markup/prism-unescaped-markup.js';
import '../../node_modules/prismjs/plugins/line-numbers/prism-line-numbers.min.js';
import '../../node_modules/prismjs/plugins/toolbar/prism-toolbar.js';
import '../../node_modules/prismjs/plugins/show-language/prism-show-language.js';
import '../../node_modules/prismjs/plugins/copy-to-clipboard/prism-copy-to-clipboard.js';
import '../../node_modules/prismjs/plugins/normalize-whitespace/prism-normalize-whitespace.js';
import '../../node_modules/prismjs/plugins/diff-highlight/prism-diff-highlight.js';
import '../../node_modules/prismjs/components/prism-css-extras.js'; // for inline-color
import '../../node_modules/prismjs/plugins/inline-color/prism-inline-color.js';
import '../../node_modules/prismjs/plugins/previewers/prism-previewers.js';
import '../../node_modules/prismjs/plugins/match-braces/prism-match-braces.js';
import '../../node_modules/prismjs/plugins/data-uri-highlight/prism-data-uri-highlight.js';

// ////
import './scripts/_theme-toggle.js';
import './scripts/_vhFix.js';
import './scripts/_toggle-menu.js';
import './scripts/_check-macOS.js';
import './scripts/_modal.js';
//import './scripts/_telegram-form.js';
import {FocusLock} from './scripts/_focus-lock.js';
import './scripts/_to-top.js';
import './scripts/_sticky-header.js';
import './scripts/_full-year.js';
window.addEventListener('DOMContentLoaded', () => {

	window.focusLock = new FocusLock();


// в load следует добавить скрипты, не участвующие в работе первого экрана
	window.addEventListener('load', () => {


	});
});


