"use strict"

import './scripts/_vhFix.js';
import './scripts/_toggle-menu.js';
import './scripts/_check-macOS.js';
import './scripts/_modal.js';
//import './scripts/_telegram-form.js';
import {FocusLock} from './scripts/_focus-lock.js';
import './scripts/_to-top.js';
import './scripts/_sticky-header.js';
import './scripts/_full-year.js';
window.addEventListener('DOMContentLoaded', () => {

	window.focusLock = new FocusLock();


// в load следует добавить скрипты, не участвующие в работе первого экрана
	window.addEventListener('load', () => {


	});
});


