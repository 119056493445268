//////////////////////////////////////////////////////////////////
// modal



	let buttons = document.querySelectorAll("[data-modal]");
	let popup = document.querySelectorAll(".modal-block");
//let popup;
// чтобы закрывалось по крестику и по любой ссылке в модалке
	let close = document.querySelectorAll(".btn--close");
	let closeLink = document.querySelectorAll(".modal-block a");

	for (let i = 0; i < buttons.length; i++) {
		buttons[i].addEventListener("click", function (evt) {
			evt.preventDefault();
			let modalId = '#' + buttons[i].getAttribute('data-modal');
			popup = document.querySelector(modalId);
			popup.classList.remove("hide");
			popup.classList.add("modal-open");
			document.querySelector('body').classList.add('body-with-open-modal');
		});
	}

	const closepopup = () => {
		// console.log(popup);
		popup.classList.add("hide");
		popup.classList.remove("modal-open");
		document.querySelector('body').classList.remove('body-with-open-modal');
	}

// закрываем по крестику
	if (close) {
		close.forEach(item => {
			item.addEventListener("click", function (evt) {
				evt.preventDefault();
				closepopup();
			});
		})
	}

// закрываем и переходим по ссылке
	if (closeLink) {
		closeLink.forEach(item => {
			item.addEventListener("click", function (evt) {
				evt.preventDefault();
				closepopup();
				window.location.href = evt.target.getAttribute('href');
			});
		})
	}


//закрываем окна по ESC
	window.addEventListener("keydown", function (evt) {
		if (evt.keyCode === 27) {
			evt.preventDefault();
			if (popup.classList.contains("modal-open")) {
				closepopup();
			}
		}
	});

//закрытие по клику мимо окна
//хак для ИЕ, чтобы работал closest и matches
	(function (evt) {
		evt.matches = evt.matches || evt.mozMatchesSelector || evt.msMatchesSelector || evt.oMatchesSelector || evt.webkitMatchesSelector;
		evt.closest = evt.closest || function closest(selector) {
			if (!this) return null;
			if (this.matches(selector)) return this;
			if (!this.parentElement) {
				return null
			} else return this.parentElement.closest(selector)
		};
	}(Element.prototype));
//end хак для ИЕ

	if (popup) {

		popup.forEach(item =>
			item.addEventListener("mouseup", function (evt) {
				if (evt.target.closest('.modal') === null) {
					closepopup();
				}
			})
		)
	}



//////////////////////////////////////////////////////////////////
// _telegram-form

//const sendToTelegram = () => {
const tgForm = document.querySelector("#tg-form");
const okBlock = document.querySelector("#is-valid-ok");
const errorBlock = document.querySelector("#is-valid-error");

const ajaxSend = async (formData) => {
	const response = await fetch("send.php", {
		method: "POST",
		body: formData
	});
	if (!response.ok) {
		throw new Error(`Ошибка, статус ошибки ${response.status}`);
	}
	return await response.text();
};

if (tgForm) {

	tgForm.addEventListener("submit", function (e) {
		e.preventDefault();
		const formData = new FormData(this);

		ajaxSend(formData)
			.then((response) => {
				console.log(response);
				tgForm.reset(); // очищаем поля формы
				closepopup(); // закрываем окно с формой
				okBlock.classList.remove("hide");
				errorBlock.classList.add("hide"); // Чтобы убралось, если было открыто
				popup = okBlock; // чтобы закрылось по крестику в modal.js

			})
			.catch((err) => {
				console.error(err);
				errorBlock.classList.remove("hide");
				//popup = errorBlock; // чтобы закрылось по крестику в modal.js
			})
	});
}
//}

//sendToTelegram();
// end telegram-form
//////////////////////////////////////////////////////////////////


// end modal
//////////////////////////////////////////////////////////////////
